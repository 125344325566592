import { IllustratedIcon, Light } from '@ornikar/illustrated-icons';
import { Input, InputRadioGroup } from '@ornikar/kitt';
import { Checkbox, Highlight, Stack, Typography, TypographyLink, VStack } from '@ornikar/kitt-universal';
import { useComposeValidators, useRequiredValidator } from '@ornikar/react-validators';
import { capitalize, isEqual } from 'lodash';
import type { ReactNode } from 'react';
import { useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import type { GeocodingDto } from '../../../apis/getAddressSuggestions';
import { Civility } from '../../../apis/types/LongQuoteAnswers';
import { AddressAutocompleteFormField } from '../../../components/AddressAutocompleteFormField';
import { DateField } from '../../../components/DateField';
import { Field } from '../../../components/Field';
import { Row } from '../../../components/Row';
import { ScreenTemplateWithValidation } from '../../../components/ScreenTemplates/ScreenTemplateWithValidation';
import type { PrimaryOrSecondaryScreenTemplateType } from '../../../components/drivingSection/types';
import { PERSONAL_INFORMATION_BANNER } from '../../../constants/flagshipKeys';
import { useBirthDateValidator } from '../../../forms/validation/sections/informations/birthDate';
import { useSubscriberEmailValidator } from '../../../forms/validation/sections/informations/email';
import { usePhoneNumberValidator } from '../../../forms/validation/sections/informations/phoneNumber';
import { useFirstNameMaxLengthValidator } from '../../../forms/validation/sharedValidators';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { mapAnswersToFormValues } from '../../../fsm/mappers/mapAnswersToFormValues';
import { Driver, Event } from '../../../fsm/types';
import { useInsuranceDesktopMediaQuery } from '../../../hooks/useInsuranceDesktopMediaQuery';
import { useFsFlag } from '../../../setup/flagship';
import { BirthCountryField } from './BirthCountryField';
import { EmailField } from './EmailField';
import { MaritalStatusField } from './MaritalStatus';
import { PhoneNumberField } from './PhoneNumberField';
import { ProfessionField } from './ProfessionField';
import { EmailErrorModal } from './components/EmailErrorModal';
import styles from './styles.module.css';

export function DriverDetailsScreen({ driverType }: PrimaryOrSecondaryScreenTemplateType): ReactNode {
  const isPrimaryDriver = driverType === Driver.Primary;

  const personalInformationFlag = useFsFlag<boolean>(PERSONAL_INFORMATION_BANNER, false, {
    shouldSendVariationToMixpanel: true,
  });

  const { context } = useSubscriptionFsmState();
  const requiredValidator = useRequiredValidator();
  const send = useSubscriptionFsmDispatch();
  const { submit } = useForm();
  const isDesktop = useInsuranceDesktopMediaQuery();

  const { values } = useFormState();

  const firstNameValidator = useComposeValidators(requiredValidator, useFirstNameMaxLengthValidator());

  const birthDateValidator = useBirthDateValidator(
    // eslint-disable-next-line  @typescript-eslint/no-non-null-asserted-optional-chain
    context.answers[`${driverType}Driver`]?.licenseDate!,
    context.answers.dateEffetSouhaite!,
    // eslint-disable-next-line  @typescript-eslint/no-non-null-asserted-optional-chain
    context.answers[`${driverType}Driver`]?.accompaniedDriving!,
  );

  const emailValidator = useSubscriberEmailValidator();

  const phoneNumberValidator = usePhoneNumberValidator();

  const initialFormattedAddress: GeocodingDto = useMemo(
    () => ({
      street: context.answers.subscriberAdress1 || '',
      city: context.answers.subscriberCommune || '',
      zipCode: context.answers.subscriberCodePostal || '',
      formattedAddress: context.answers.subscriberCompleteAdress || '',
    }),
    [
      context.answers.subscriberAdress1,
      context.answers.subscriberCommune,
      context.answers.subscriberCodePostal,
      context.answers.subscriberCompleteAdress,
    ],
  );

  const fieldNames = [
    `${driverType}Driver.firstName`,
    `${driverType}Driver.lastName`,
    `${driverType}Driver.civility`,
    `${driverType}Driver.birthDate`,
  ];

  if (isPrimaryDriver) {
    fieldNames.push(
      'primaryDriver.birthCity',
      'primaryDriver.birthCountryCode',
      'primaryDriver.profession',
      'primaryDriver.maritalStatus',
      'subscriberEmail',
      'subscriberPhone',
      'subscriberAutoCompletedAddress',
    );
  }

  const [modalVisible, setModalVisible] = useState(false);

  const innerSubmit = async (): Promise<void> => {
    const submitResult = await submit();

    if (submitResult?.isEmailDomainBlacklisted) {
      setModalVisible(true);
    }
  };

  const handleSubmit = (): void => {
    const { privacyPolicyAccepted, ...restValues } = values;
    const { privacyPolicyAccepted: lastPrivacyPolicyAccepted, ...restLastQuoteRequest } =
      context.lastQuoteRequest || {};

    if (context.tarificationResult && isEqual(mapAnswersToFormValues(restLastQuoteRequest), restValues)) {
      send({ type: Event.CONTINUE });
    } else {
      innerSubmit();
    }
  };

  return (
    <ScreenTemplateWithValidation
      fieldNames={fieldNames}
      title={
        isPrimaryDriver ? (
          <div>
            Ça y est votre tarif est prêt ! Pour l&apos;obtenir, il ne vous reste plus qu&apos;à remplir ces dernières
            informations.
          </div>
        ) : (
          <div>Afin que ce conducteur bénéficie de votre couverture, pouvez-vous nous en dire plus sur lui&nbsp;?</div>
        )
      }
      submitButton={{
        label: isPrimaryDriver ? 'Obtenir mon tarif' : 'Continuer',
      }}
      onSubmit={handleSubmit}
    >
      {personalInformationFlag.getValue() && (
        <VStack marginBottom={isDesktop ? 'kitt.8' : 'kitt.6'}>
          <Highlight>
            <Stack direction="row">
              <VStack marginRight="kitt.2">
                <IllustratedIcon icon={<Light />} />
              </VStack>
              <Typography.Paragraph>
                Assurez-vous de communiquer des renseignements exacts avant de continuer votre devis. Vous en aurez
                besoin pour finaliser votre souscription.
              </Typography.Paragraph>
            </Stack>
          </Highlight>
        </VStack>
      )}
      <Row>
        <Field
          component={Input}
          name={`${driverType}Driver.firstName`}
          label={isPrimaryDriver ? 'Votre prénom' : 'Son prénom'}
          validate={firstNameValidator}
          className={styles.Field}
          format={capitalize}
        />
        <Field
          component={Input}
          name={`${driverType}Driver.lastName`}
          label={isPrimaryDriver ? 'Votre nom' : 'Son nom'}
          validate={requiredValidator}
          className={styles.Field}
          format={capitalize}
        />
      </Row>
      <Row>
        <Field
          component={InputRadioGroup}
          name={`${driverType}Driver.civility`}
          className={styles.Field}
          choices={[
            {
              label: 'M.',
              value: Civility.Homme,
            },
            {
              label: 'Mme.',
              value: Civility.Femme,
            },
          ]}
          label={isPrimaryDriver ? 'Votre civilité' : 'Sa civilité'}
          validate={requiredValidator}
        />
        <DateField
          name={`${driverType}Driver.birthDate`}
          className={styles.Field}
          label={isPrimaryDriver ? 'Votre date de naissance' : 'Sa date de naissance'}
          validate={birthDateValidator}
        />
      </Row>
      {isPrimaryDriver ? (
        <>
          <Row>
            <Field
              autoComplete="none"
              component={Input}
              name="primaryDriver.birthCity"
              label="Votre ville de naissance"
              validate={requiredValidator}
              className={styles.Field}
            />
            <BirthCountryField
              label="Votre pays de naissance"
              autoComplete="none"
              name="primaryDriver.birthCountryCode"
              validate={requiredValidator}
              className={styles.Field}
            />
          </Row>
          <Row>
            <ProfessionField validate={requiredValidator} />
            <MaritalStatusField validate={requiredValidator} />
          </Row>
          <Row>
            <EmailField validate={emailValidator} />
            <PhoneNumberField name="subscriberPhone" validate={phoneNumberValidator} />
          </Row>
          <Row>
            <AddressAutocompleteFormField
              name="subscriberAutoCompletedAddress"
              initialFormattedAddress={initialFormattedAddress}
              label="Votre adresse"
            />
          </Row>
          <Row>
            <Field
              name="hasAcceptedCommercialCommunication"
              type="checkbox"
              component={Checkbox}
              className={styles.CommercialCommunication}
            >
              <div className={styles.CheckboxLabel}>
                Je souhaite être informé.e des offres et avantages exclusifs du groupe Ornikar.
              </div>
            </Field>
          </Row>
          <Row>
            <Field
              name="hasAcceptedPartnersCommunication"
              type="checkbox"
              component={Checkbox}
              className={styles.CommercialCommunication}
            >
              <div className={styles.CheckboxLabel}>
                Je souhaite recevoir les bons plans des{' '}
                <TypographyLink
                  variant="bold"
                  href="https://www.ornikar.com/a-propos/nos-partenaires-education-et-assurance"
                  hrefAttrs={{ target: '__blank' }}
                  onPress={(e) => e.stopPropagation()}
                >
                  partenaires sélectionnés
                </TypographyLink>{' '}
                par Ornikar.
              </div>
            </Field>
          </Row>
          <VStack marginTop="kitt.3">
            <Typography.Paragraph color="black-light" base="body-xsmall">
              Ornikar peut vous contacter par téléphone et d’autres moyens de communication au sujet de votre devis pour
              vous accompagner dans votre souscription. Vos données personnelles sont utilisées par Ornikar Assurances à
              des fins d’appréciation du risque, de proposition de devis, de passation, gestion et exécution des
              contrats d’assurance, prospection commerciale du groupe Ornikar et lutte contre la fraude. Pour en savoir
              plus et connaître vos droits RGPD, consultez notre{' '}
              <TypographyLink
                variant="bold"
                hrefAttrs={{ target: '__blank' }}
                href="https://www.ornikar.com/a-propos/charte-de-confidentialite"
                onPress={(e) => e.stopPropagation()}
              >
                politique de confidentialité
              </TypographyLink>
              .
            </Typography.Paragraph>
          </VStack>
        </>
      ) : null}
      <EmailErrorModal visible={modalVisible} email={values.subscriberEmail} onClose={() => setModalVisible(false)} />
    </ScreenTemplateWithValidation>
  );
}
